export enum ExemptionType {
    PRIVATE_DISPOSAL = "PRIVATE_DISPOSAL",
    NO_DISPOSAL = "NO_DISPOSAL",
    DISPOSAL_FREE_OF_CHARGE = "DISPOSAL_FREE_OF_CHARGE",
    MANUAL_PAYMENT = "MANUAL_PAYMENT",
    OTHER = "OTHER",
    CANCELLED = "CANCELLED",
    DETECTED = "DETECTED",
    DEFAULT = "DEFAULT"
}

export const exemptorReasons = [
    ExemptionType.DEFAULT, ExemptionType.DETECTED
]