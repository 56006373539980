import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {Autocomplete, Backdrop, CircularProgress, OutlinedTextFieldProps, Select, TextField, Tooltip, useTheme} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import BillingTable from "./BillingTable";
import ReceiptIcon from "@mui/icons-material/Receipt";
import {useTranslation} from "react-i18next";
import {acquireToken, ErrorResponse} from "../../api/apiUtils";
import {isEmptyArray} from "../../helpers/IsEmptyHelpers";
import Typography from "@mui/material/Typography";
import SearchBar from "../Shared/SearchBar";
import {fetchBillableVisits, fetchBillableVisitsBySearchCriteria, fetchPayableStats} from "../../api/BillableVisitsAPI";
import {BillableVisit} from "../../types/BillableVisit";
import Button from "@mui/material/Button";
import {GridPaginationModel, GridSortModel, useGridApiRef} from "@mui/x-data-grid";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import ChipFilter, {FilterItem, FilterType} from "../Shared/ChipFilter";
import dayjs, { Dayjs } from "dayjs";
import 'dayjs/locale/sv';
import 'dayjs/locale/en';
import FacilityAutocomplete from "../Shared/FacilityAutocomplete";
import {Facility} from "../../types/Facility";
import {useCurrentCustomer} from "../../contexts/CurrentCustomerContext";
import {FacilityStats, PayableStats} from "../../types/PayableStats";
import {findPricePeriodByCustomerId} from "../../api/PricePeriodAPI";
import {useCurrentUser} from "../../contexts/CurrentUserContext";
import {getCurrentLanguage} from "../../helpers/LanguageHelper";
import i18n from "../../i18n";
import { allAdminUserRoles, nonViewerRoles } from "../../types/CurrentUserRoles";
import { formatNumberOfVisits, formatSEK } from "../../helpers/PayableAmountFormatting";
import {TableSettings, TableStorage} from "../../types/settings/TableSettings";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { CurrentUserGroups } from "../../types/CurrentUserGroups";

export default function Billing() {
    const shouldShowExportButton = true;
    const theme = useTheme();
    const {t} = useTranslation();
    const gridApiRef = useGridApiRef();
    const [rowChanged, setRowChanged] = React.useState<boolean>(false);
    const [filterDate, setFilterDate] = useState<Dayjs | null>(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({page: 0, pageSize:TableStorage.loadLocalStorage()});
    const [pageChanged, setPageChanged] = useState(false);
    const [sortModel, setSortModel] = useState<GridSortModel>([{field: 'timeStamp', sort: 'desc'}])
    const [totalElements, setTotalElements] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<ErrorResponse[]>([]);
    const {currentCustomer} = useCurrentCustomer();
    const {currentUser} = useCurrentUser();
    const [facilitiesFilterValue, setFacilitiesFilterValue] = useState<Facility[]>([]);
    const [filteredVisits, setFilteredVisits] = useState<BillableVisit[]>([]);
    const [selectedFilters, setSelectedFilters] = useState<FilterItem[]>([]);
    const [payableStats, setPayableStats] = useState<PayableStats>();
    const [facilityPrice, setFacilityPrice] = useState<number | null>(null);
    const [isExporting, setIsExporting] = useState(false);

    const currentMonth = dayjs().startOf('month');
    const today = dayjs().endOf('day');
    const [selectedMonth, setSelectedMonth] = useState<Dayjs>(currentMonth);
    const [selectedYear, setSelectedYear] = useState({id: currentMonth.year()});
    const [selectedYearInputValue, setSelectedYearInputValue] = useState(selectedYear.id.toString());
    const selectableYears = [{id: 2021}, {id: 2022}, {id: 2023}, {id: 2024}, {id: 2025}, {id: 2026}]
        .filter(item => currentMonth.year() >= item.id);

    useEffect(() => {
        fetchInitialData();
        fetchPayableStatsData();
    }, []);

    useEffect(() => {
        if (searchQuery || facilitiesFilterValue.length || filterDate || selectedFilters.length || !showingCurrentMonth()) {
            filterBillableVisits();
        } else {
            fetchInitialData();
        }
        fetchPayableStatsData();
    }, [searchQuery, facilitiesFilterValue, filterDate, selectedFilters, rowChanged, pageChanged, sortModel, selectedMonth]);

    const fetchInitialData = async () => {
        try {
            setLoading(true);
            const {data, errors, page} = await fetchBillableVisits(
                paginationModel.pageSize, 
                paginationModel.page,
                mapSortModelFields(sortModel), 
                currentCustomer.id,);
            setFilteredVisits(data);
            setErrors(errors);
            page && setTotalElements(page.totalElements)
        } finally {
            setLoading(false);
        }
    };

    const filterBillableVisits = async () => {
        setLoading(true);

        try {
            const {fromTimestamp, toTimestamp} = calculateTimestampRange(filterDate);
            const facilityIds = extractFacilityIds(facilitiesFilterValue);
            const {typeFilters, customFilters} = extractFilters(selectedFilters);

            const {data, errors, page} = await fetchBillableVisitsBySearchCriteria(
                paginationModel.pageSize,
                paginationModel.page,
                mapSortModelFields(sortModel),
                currentCustomer.id,
                encodeURIComponent(searchQuery),
                facilityIds,
                fromTimestamp,
                toTimestamp,
                typeFilters,
                customFilters,
                showingCurrentMonth() ? 'billableVisits' : 'previousVisits'
            );

            setFilteredVisits(data);
            setErrors(errors);
            page && setTotalElements(page.totalElements)
        } finally {
            setLoading(false);
        }
    };

    const isDemoServiceActive = (data: any): boolean => {
        const demoService = data.services.find((service: { type: string; }) => service.type === 'DEMO_AUTO_INVOICING');
        return demoService ? demoService.active : null;
    }

    const showPayableStatsForUser = (): boolean => {
        return currentUser.roles.some(r => allAdminUserRoles.map(role => role.id).includes(r.id));
    }

    const fetchPayableStatsData = async () => {
        const {data, errors} = await fetchPayableStats(encodeURIComponent(currentCustomer.id), selectedMonth.format('YYYY-MM'));
        if (isEmptyArray(errors)) {
            setPayableStats(data);
        }
        else {
            setPayableStats(undefined);
        }
    }

    const fetchFacilityPrice = async (facilityId: number) => {
        const {data, errors} = await findPricePeriodByCustomerId(encodeURIComponent(currentCustomer.id));
        if (isEmptyArray(errors)) {
            const pricePeriod = data.find(pricePeriod => pricePeriod.facilityId === facilityId);
            if (pricePeriod) {
                setFacilityPrice(pricePeriod.currentPrice);
            }
        }
    }

    const calculateTimestampRange = (filterDate: Dayjs | null) => {
        let fromTimestamp: number | undefined;
        let toTimestamp: number | undefined;
        if (filterDate) {
            const startOfDay = filterDate.startOf('day');
            const endOfDay = startOfDay.endOf('day');
            fromTimestamp = startOfDay.valueOf();
            toTimestamp = endOfDay.valueOf();
        }
        else if (!showingCurrentMonth()){
            fromTimestamp = dayjs(selectedMonth).startOf('month').startOf('day').valueOf();
            toTimestamp = dayjs(selectedMonth).endOf('month').endOf('day').valueOf();
        }
        return {fromTimestamp, toTimestamp};
    };

    const extractFacilityIds = (facilitiesFilterValue: Facility[]) => {
        return facilitiesFilterValue.map((facility) => facility.id);
    };

    const extractFilters = (selectedFilters: FilterItem[]) => {
        const typeFilters: string[] = [];
        const customFilters: string[] = [];
        selectedFilters.forEach((filterItem) => {
            if (filterItem.filterType === FilterType.TYPE_FILTER) {
                typeFilters.push(filterItem.value as string);
            } else if (filterItem.filterType === FilterType.CUSTOM_FILTER) {
                customFilters.push(filterItem.value as string);
            }
        });
        return {typeFilters, customFilters};
    };

    const showingCurrentMonth = ():boolean => {
        return selectedMonth.isSame(currentMonth); 
    }
    
    const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const handleDateChange = (date:Dayjs | null) => {
        if (date && date.isValid()) {
            setFilterDate(date);
        }
    };

    const handleSelectedMonthChange = (date:Dayjs | null) => {
        if (date != null && date.isValid()) {
            setSelectedMonth(date.startOf('month'));
        }
        else {
            setSelectedMonth(currentMonth);
            setSelectedYear({id: currentMonth.year()});
            setSelectedYearInputValue(currentMonth.year().toString())
        }
    }

    const handleSelectedYearChange = (year: {id:number} | null) => {
        setSelectedYear({id: year? year.id : currentMonth.year()});
        let newMonth = selectedMonth.set('year', year? year.id : currentMonth.year());
        setSelectedMonth(!newMonth.isAfter(currentMonth)? newMonth : currentMonth);
    }

    const handleSelectedFiltersChange = (filters: any) => {
        setSelectedFilters(filters)
    };

    const handleFilterFacilitiesChange = (facilities: Facility[]) => {
        if (facilities.length === 1) {
            fetchFacilityPrice(parseInt(facilities[0].id));
        } else {
            setFacilityPrice(null);
        }
        setFacilitiesFilterValue(facilities);
    };

    const handleChangePagingModel = (newModel: GridPaginationModel) => {
        TableStorage.saveLocalStorage(newModel.pageSize);
        setPaginationModel(newModel);
        setPageChanged(prev => !prev);
    };

    const formatPayableStats = (payableStats: PayableStats) => {
        return {
            totalPayableVisits: payableStats && payableStats.totalPayableVisits? formatNumberOfVisits(payableStats.totalPayableVisits) : 0,
            totalAccumulatedPayableAmount: payableStats? formatSEK(payableStats.totalAccumulatedPayableAmount) : '0'

        }
    };

    const formatFacilityStats = (payableFacilityStats: FacilityStats) => {
        return {
            numberOfPayableVisits: payableFacilityStats && payableFacilityStats.numberOfPayableVisits? formatNumberOfVisits(payableFacilityStats.numberOfPayableVisits) : 0,
            accumualatedPayableAmount: payableFacilityStats? formatSEK(payableFacilityStats.accumualatedPayableAmount) : '0'
        }
    }

    const mapSortModelFields = (model: GridSortModel): GridSortModel => {
        if(model.length > 0) {
            const {field, sort} = model[0];
            let sortField;
            if(field === 'timeStamp'){
                sortField = 'vehicleData.sensorTime';
            }
            else if(field === 'regnumberClear') {
                sortField = 'vehicleData.regnumberClear'
            }
            else if(field === 'company') {
                sortField = 'vehicleData.ownerName'
            }
            else if(field === 'exception') {
                sortField = 'watchListTitle'
            }
            else {
                sortField = field;
            }
            return [{field: sortField, sort: sort}];
        } else {
            return [];
        }
    };

    const handleCsvExport = () => {
        setIsExporting(true);
        const params = [
            `customerId=${currentCustomer.id}`,
            `time=${selectedMonth.valueOf()}`,
            facilitiesFilterValue.length > 0 ? `facilityIds=${extractFacilityIds(facilitiesFilterValue)}` : ''
        ]
        const url = `/api/visits/search/getCsvFile?${params.join('&')}`
        const options = {
            method: "GET"
        }
        acquireToken().then((token) => {
            const headers = new Headers();
            headers.append("Authorization", `Bearer ${token}`);
            const request = new Request(url, options);
            return fetch(request, {headers: headers}).then((response) => {
                response.blob().then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = `${t("Fakturering")} - ${currentCustomer.name} - ${selectedMonth.format('YYYY-MM')}.csv`
                    document.body.appendChild(link);
                    link.click();
        
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                })
                .catch((error) => {
                    console.error("Error fetching file:", error);
                }).finally(() => setIsExporting(false));
            }).catch((error) => {
                console.error("Error fetching file:", error)
            }).finally(() => setIsExporting(false)
            );
        })
    }

    const hasNonViewerRole = () => {
        return currentUser && currentUser.isAuthorized && nonViewerRoles.some(r => currentUser.roles.some(cur => cur.id === r.id));
    }

    const hasExportGroup = () => {
        return currentUser && currentUser.isAuthorized && currentUser.groups.some(ug => ug.name.includes(CurrentUserGroups.CSV_EXPORT) && currentCustomer.customerGroups.some(cg => cg.id === ug.id));
    }

    return (
        <Box sx={{height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column', gap: theme.spacing(2)}}>
            <Box sx={{marginBottom: theme.spacing(2), maxWidth: '95%'}}>
                <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
                    <Grid item sx={{display: 'flex', alignItems: 'center', gap: theme.spacing(1)}}>
                        <ReceiptIcon/>
                        <h2>{t("Fakturering")}</h2>
                        {isDemoServiceActive(currentCustomer) && (
                            <Grid item xs={'auto'}>
                                <Typography variant={"h6"} sx={{color: 'red'}}>{t("Demo_text")}</Typography>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={"auto"} sx={{textAlign: 'right', mb: {xs: 1}}}>
                        {showPayableStatsForUser() && payableStats && (
                            facilityPrice === null ? (
                                <Typography variant={'h6'}>{t("Payable_Stats", {payableStats: formatPayableStats(payableStats)})}</Typography>
                            ) : (
                                <Typography variant={'h6'}>{t("Payable_Stats_Facility", {
                                    payableStats: formatFacilityStats(payableStats.stats[facilitiesFilterValue[0].id]),
                                    facilityPrice: formatSEK(facilityPrice)
                                })}</Typography>
                            )
                        )}
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={6} md={3} xl={2}>
                    <Autocomplete
                                selectOnFocus
                                clearOnBlur
                                id="year-select"
                                options={selectableYears.sort((a,b) => a.id < b.id ? 1 : -1)}
                                getOptionLabel={(option) => option.id.toString()}
                                value={selectedYear}
                                onChange={(e, value) => handleSelectedYearChange(value)}
                                inputValue={selectedYearInputValue}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onInputChange={(e, newInputValue) => setSelectedYearInputValue(newInputValue)}
                                renderInput={(params) => (
                                    <TextField 
                                    {...params as unknown as OutlinedTextFieldProps} 
                                    variant="outlined"
                                    label={t("Välj_år")}
                                    />
                                )}
                                sx={{width: "100%", background: theme.palette.common.white}}
                            />
                    </Grid>
                   <Grid item xs={6} md={3} xl={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getCurrentLanguage(i18n.language)}>
                            <DatePicker
                                label={t("Välj_månad")}
                                onChange={(handleSelectedMonthChange)}
                                value={selectedMonth}
                                minDate={dayjs(selectedYear.id, 'year').startOf('year')}
                                maxDate={dayjs()}
                                format="YYYY-MM"
                                views={['month']}
                                sx={{width: "100%", background: theme.palette.common.white}}
                                slotProps={{
                                    field: {clearable: true, onClear: () => {setSelectedMonth(currentMonth); setFilterDate(null);}, value: showingCurrentMonth()? currentMonth : selectedMonth },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6} md={3} xl={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getCurrentLanguage(i18n.language)}>
                            <DatePicker
                                label={t("Filtrera_Datum")}
                                value={filterDate}
                                format="YYYY-MM-DD"
                                onChange={handleDateChange}
                                minDate={selectedMonth.startOf('month').startOf('day')}
                                maxDate={selectedMonth.isBefore(today.startOf('month')) ? selectedMonth.endOf('month').endOf('day') : today.endOf('day')}
                                views={['day']}
                                sx={{width: "100%", background: theme.palette.common.white}}
                                slotProps={{
                                    field: {clearable: true, onClear: () => setFilterDate(null)},

                            }}/>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6} md={3} xl={3} sx={{mt: { xs: 0, md: 0 }}}>
                        <SearchBar
                            label={t("Regnummer/Orgnummer")}
                            onChange={handleSearchQueryChange}
                            searchQuery={searchQuery}
                            size={"medium"}
                            helperText={t("Regnummer_eller_företag")}
                        />
                    </Grid>
                </Grid>
                <Box>
                    <Grid container mt={3} spacing={1}>
                        <Grid item xs={12} md={6} xl={8}>
                            <ChipFilter
                                onSelectedFiltersChange={handleSelectedFiltersChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} xl={4}>
                        <FacilityAutocomplete
                            label={t("Välj_Anläggningar")}
                            limitTags={3}
                            currentCustomer={currentCustomer}
                            onFacilitiesFilterChange={handleFilterFacilitiesChange}/>
                    </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{maxHeight: '70%'}}>
                {!isEmptyArray(errors) && errors.map((error, index) => (
                    <Typography key={index} variant="body1" style={{color: 'red'}}>
                        {error.message}
                    </Typography>
                ))}
                {isEmptyArray(errors) && (
                    <BillingTable
                        billableVisits={filteredVisits}
                        loading={loading}
                        setRowChanged={setRowChanged}
                        gridApiRef={gridApiRef}
                        onPaginationModelChange={handleChangePagingModel}
                        totalElements={totalElements}
                        paginationModel={paginationModel}
                        showingCurrentMonth={showingCurrentMonth()}
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                    />
                )}
            </Box>
            {shouldShowExportButton && (hasNonViewerRole() || hasExportGroup()) && (
            <Box sx={{maxWidth: {xs: '100%', sm: '95%'}}}>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    mt={3}
                    pb={3}>
                    <Grid item xs="auto">
                        <Tooltip title={t("ExportCSV_help")}>
                        <Button startIcon={<CloudDownloadIcon/>} variant="outlined" type="submit" color="inherit" disabled={isExporting}
                                onClick={handleCsvExport}>{t("Exportera_CSV")}
                        {isExporting && <CircularProgress color="inherit" size={16} sx={{position: "absolute"}}/>}
                    </Button>
                    </Tooltip>
                    </Grid>
                </Grid>
            </Box>
            )}
        </Box>
    )
        ;
}