import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import BusinessIcon from "@mui/icons-material/Business";
import {styled} from "@mui/material/styles";
import {AppSettingsAlt, Help, Logout, Report, Warehouse} from "@mui/icons-material";
import { Typography } from '@mui/material';
import { useCurrentCustomer } from '../../contexts/CurrentCustomerContext';
import { useCurrentUser } from '../../contexts/CurrentUserContext';
import { useMsal } from '@azure/msal-react';
import CustomMobileDrawerListItem from '../Shared/CustomMobileDrawerListItem';

const MobileDrawerHeader = styled("div")(({theme, hidden}) => ({
    display: hidden ? "none" : "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

interface CustomMobileDrawerProps {
    open: boolean;
    hidden: boolean;
    handleDrawerClose: () => void;
}

const CustomMobileDrawer: React.FC<CustomMobileDrawerProps> = ({open, hidden, handleDrawerClose,}) => {
    const {i18n} = useTranslation();
    const {setCurrentCustomer} = useCurrentCustomer();
    const {currentUser, setCurrentUser} = useCurrentUser();
    const {instance} = useMsal();
    
    const toggleLanguage = () => {
        if(i18n.language === "svSE") {
            i18n.changeLanguage("en");
        }
        else {
            i18n.changeLanguage("svSE")
        }
    }

    const handleLogout = () => {
        setCurrentCustomer({})
        setCurrentUser({});
            instance.logoutRedirect({
                postLogoutRedirectUri: "/realtimeview"
            });

    };

    const multiCustomerUserDrawerItems = [
        {
            text: "Kunder",
            icon: <BusinessIcon/>,
            url: "/customers",
            action: () => {}
        }
    ]
    const selectDrawerItems = [
        {
            text: "Anläggning",
            icon: <Warehouse/>,
            url: "realtimeview/facilityselection",
            action: () => {}
        },
        {
            text: "Inställningar",
            icon: <AppSettingsAlt/>,
            url: "/realtimeview/settings",
            action: () => {}
        },
    ]
    const miscDrawerItems = [
        {
            text: "Ändra_Språk_sv_en",
            icon: <SwapHorizIcon/>,
            url:"/realtimeview",
            action: toggleLanguage
        },
        {
            text: "Rapportera ett problem",
            icon: <Report/>,
            url: "/realtimeview/report",
            action: () => {}
        },
        {
            text: "Hjälp",
            icon: <Help/>,
            url: "/realtimeview/help",
            action: () => {}
        },
        {
            text: "Logga ut",
            icon: <Logout/>,
            url: "/realtimeview",
            action: handleLogout
        },
    ];


    const DrawerList = (
        <Box sx={{width: 250}} role="presentation" onClick={handleDrawerClose}>
            {(currentUser.isAdmin || currentUser.customerIds?.length > 1) && multiCustomerUserDrawerItems.map((item) => 
                <CustomMobileDrawerListItem item={item} key={item.text} open={open}/>
            )}
            {selectDrawerItems.map((item) => 
                <CustomMobileDrawerListItem item={item} key={item.text} open={open}/>
            )}
            <Divider/>
            {miscDrawerItems.map((item) => 
                <CustomMobileDrawerListItem item={item} key={item.text} open={open}/>
            )}
        </Box>
    )

    return (
        <div hidden={hidden}>
            <Drawer open={open} onClose={handleDrawerClose} anchor={"right"}>
                <MobileDrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                        <ChevronRightIcon/>
                    </IconButton>
                </MobileDrawerHeader>
                <Typography variant="subtitle2" align='center'>{currentUser.fullName}</Typography>
                <Divider/>
                {DrawerList}
            </Drawer>
        </div>
    );
}
export default CustomMobileDrawer;