import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridEditInputCell, GridPaginationModel, GridPreProcessEditCellProps, GridRenderEditCellParams, GridRowParams, GridSortModel, GridValueGetterParams } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { setUserRolesAndGroupsAndFacilities } from '../../api/UserAPI';
import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useGridApiRef } from '@mui/x-data-grid';
import UpdateUserModal from "./Modals/UpdateUserModal";
import {CustomNoRowsOverlay} from "../Shared/Tables/CustomNoRowsOverlay";
import { Tooltip } from '@mui/material';
import { boxStyle } from '../Shared/Tables/listStyles';
import { rowToUser, User } from '../../types/User';
import { dateColumnType } from '../Shared/Tables/GridEditDateCell';
import { useCurrentCustomer } from '../../contexts/CurrentCustomerContext';
import { customersWithUserNameLoginType } from '../../types/Customer';

interface UserTableProps {
    userItems: User[];
    loading: boolean;
    handleProcessRowUpdate: (e:any, d:any) => void,
    totalElements: number;
    paginationModel: GridPaginationModel;
    onPaginationModelChange: (newModel: GridPaginationModel) => void;
    sortModel: GridSortModel;
    onSortModelChange: (newSortModel: GridSortModel) => void;
}

const UserTable = (({userItems, loading, totalElements, paginationModel, onPaginationModelChange, sortModel, onSortModelChange, handleProcessRowUpdate}: UserTableProps) => {
    const { t } = useTranslation();
    const gridApiRef = useGridApiRef();
    const {currentCustomer} = useCurrentCustomer();
    const [user, setUser] = useState<User>(rowToUser({}));
    const [updating, setUpdating] = useState(false);

    const checkEmptyString = (params: GridPreProcessEditCellProps) => {
        const hasErrors = params.props.value.length < 1;
        return {...params.props, error: hasErrors};
    }

    const renderEditName = (params: GridRenderEditCellParams) => {
        const {error} = params;
        return <Tooltip open={!!error} title={t("Namn_får_inte_vara_tom")}>
                    <GridEditInputCell {...params}/>
                </Tooltip>
      }
      
      const formatFacilityColumn = (params: GridValueGetterParams) => {
        let value = params.value.filter(f => currentCustomer.facilities.some(cf => cf.id === f.id));
        if (value == null){
            return '';
        }
        else if (value.length > 1) {
            return `${t(value[0].name)} + ${value.length - 1}`;
        }
        else {
            return `${value.map(v => t(v.name)).join(', ')}`;
        }
      }

      const formatRoleColumn = (params: GridValueGetterParams) => {
        let value = params.value.sort((a,b) => a.id < b.id)
        if (value == null){
            return '';
        }
        else {
            return `${value.map(v => t(v.name)).join(', ')}`;
        }
    }
    
    const formatGroupColumn = (params: GridValueGetterParams) => {
        let value = params.value.filter(v => currentCustomer.customerGroups.some(cg => cg.id === v.id)).sort((a,b) => a.id < b.id)
        if (value == null){
            return '';
        }
        else if (value.length > 2) {
            return `${t(value[0].name)}, ${t(value[1].name)} + ${value.length -2}`;
        }
        else {
            return `${value.map(v => t(v.name)).join(', ')}`;
        }
    }
    


    const columns: GridColDef[] = [
        {field: 'active', headerName: t("Aktiv"), editable: true, minWidth: 50, maxWidth:100, flex: 1, type: 'boolean', disableColumnMenu: true, renderCell: (params) => {
            return <Tooltip title={params.row.active? t("Aktiv"): t("Inaktiv")}>{params.row.active? <CheckIcon fontSize="small"/>: <CloseIcon fontSize='small'/>}</Tooltip>
        }},
        {field: 'expires', ...dateColumnType, headerName: t("Giltig_till"), editable: true, type: 'date', minWidth: 50, maxWidth: 150, flex: 1, disableColumnMenu: true},
        {field: 'fullname', editable: false, renderEditCell: renderEditName, preProcessEditCellProps: checkEmptyString, headerName: t("Namn"), minWidth: 50, maxWidth: 250, flex: 1, disableColumnMenu: true},
        {field: 'username', headerName: t("Användarnamn"), minWidth: 50, maxWidth: 250, flex: 1, disableColumnMenu: true},
        {field: 'email', headerName: t("Email"), editable: customersWithUserNameLoginType.includes(currentCustomer.id.toString()),
            minWidth: 50, maxWidth: 250, flex: 1, disableColumnMenu: true},
        {field: 'phone', headerName: t("Telefonnummer"), editable: true, sortable: false, minWidth: 50, maxWidth: 150, flex: 1, disableColumnMenu: true},
        {field: 'userRoles', headerName: t("Roller"), sortable: false, valueGetter: formatRoleColumn, minWidth: 50, maxWidth: 150, flex:1, disableColumnMenu: true},
        {field: 'userGroups', headerName: t("Grupper"), sortable: false, valueGetter: formatGroupColumn, minWidth: 50, maxWidth:300, flex:1, disableColumnMenu: true},
        {field: 'userFacilities', headerName: t("Anläggningar"), sortable:false, valueGetter: formatFacilityColumn, minWidth: 50, maxWidth: 250, flex:1, disableColumnMenu: true},
        {field: 'edit', headerName: '', editable: false, sortable: false, disableColumnMenu: true, width: 65, renderCell: () => {
            return <Tooltip title={t("Redigera_Roller_Grupper_Anläggningar")} sx={{cursor: "pointer"}}><EditIcon/></Tooltip>}
        }
    ];

    const generateRows = (userItem: User): any[] => {
        const rows: any[] = [{
            id: userItem.id,
            username: userItem.username,
            fullname: userItem.fullname,
            email: userItem.email,
            phone: userItem.phone,
            active: userItem.active,
            expires: userItem.expires,
            userRoles: userItem.userRoles,
            userGroups: userItem.userGroups,
            userFacilities: userItem.userFacilities
        }];

        return rows.reduce((result, currentObj) => {
            return {...result, ...currentObj};
        });
    };


    useEffect(() => {
        setRows(userItems.map(generateRows));
    }, [userItems]);

    const handleConfirmSetRolesGroups = async(updatedItem) => {
        const {data, errors} = await setUserRolesAndGroupsAndFacilities(user.id, updatedItem.roles, updatedItem.groups, updatedItem.facilities);
        gridApiRef.current.updateRows([data]);
        handleClose();
    };

    const handleClose = () => {
        setUpdating(false);
    };

    const [rows, setRows] = useState(() => userItems.map(generateRows));

    const handleCellClick = (params:any) => {
        if(params.field === 'edit') {
            setUpdating(true);
            setUser(rowToUser(params.row));
        }
    }

    const activeUserClassName = (params: GridRowParams) => {
        return params.row.active? 'custom-active-item' : 'custom-inactive-item';
    };

    return (
        <Box sx={boxStyle}>
            <Paper>
            <DataGrid
                    columnVisibilityModel={{email: customersWithUserNameLoginType.includes(currentCustomer.id.toString())}}
                    sx={{height: '55vh', maxHeight: '65vh', width: '100%', '--DataGrid-overlayHeight': '300px'}}
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    getRowId={(row: any) => row.id}
                    processRowUpdate={handleProcessRowUpdate}
                    getRowClassName={activeUserClassName}
                    paginationMode='server'
                    rowCount={totalElements?? 0}
                    paginationModel={paginationModel}
                    onPaginationModelChange={onPaginationModelChange}
                    sortingMode='server'
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    sortingOrder={['desc', 'asc']}
                    pageSizeOptions={[10, 25, 50, 100]}
                    apiRef = {gridApiRef}
                    hideFooterSelectedRowCount={true}
                    onCellClick={handleCellClick}
                    hideFooter={false}
                    slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                        pagination: {
                            showFirstButton: true,
                            showLastButton: true,
                        },
                    }}
                />
            </Paper>
            <Box sx={{maxWidth: {xs: '100%', sm: '95%'}}}>
                <Grid container spacing={2}>
                    <Grid item xs="auto" sx={{ marginLeft: { xs: 0, md: "auto"}}}>
                        <UpdateUserModal open={updating} handleClose={handleClose} handleConfirmChanges={handleConfirmSetRolesGroups} data={user}/>
                    </Grid>
                </Grid>
            </Box>
        </Box>
);
});
export default UserTable;