import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {Backdrop, CircularProgress, Stack, ToggleButton, ToggleButtonGroup} from "@mui/material";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Typography from "@mui/material/Typography";
import { updateExempt} from "../../../api/BillableVisitsAPI";
import {ErrorResponse} from "../../../api/apiUtils";
import {ExemptionType, exemptorReasons} from "../../../types/enums/ExemptionType";
import CloseIcon from "@mui/icons-material/Close";
import { ExemptStatsComponent } from "../../Shared/ExemptStatsComponent";
import { customersWithExemptors } from "../../../types/Customer";
import { useCurrentCustomer } from "../../../contexts/CurrentCustomerContext";


export function ExemptReasonComponent({visitData, stats,close,back}) {
    const { t } = useTranslation();
    const [exemptSelect, setExemptSelect] = React.useState<ExemptionType>();
    const [errors, setErrors] = useState<ErrorResponse[]>([]);
    const {currentCustomer} = useCurrentCustomer();

    useEffect(() => {
        if(visitData.exemption){
            setExemptSelect(visitData.exemption.reason)
        }
    }, [visitData]);

    const handleSelect = (event, newExempt: ExemptionType | null,) => {
        if (newExempt !== null) {
            setExemptSelect(newExempt);
            setExemptReason(newExempt)
        }
    };

    const setExemptReason = async (reason: ExemptionType) => {
        const {data, errors} = await updateExempt(visitData.id,reason,visitData.comment ?? '');
        setErrors(errors)

        if(data){
            back(data);
        }
    };

    return (
        <>
                <Box>
                    <Stack sx={{backgroundColor:'',width:"100%"}} spacing={1} justifyContent="center"  direction="row" >
                        <ToggleButtonGroup
                            color="primary"
                            value={exemptSelect}
                            exclusive
                            onChange={handleSelect}
                            aria-label="Platform"
                            orientation="vertical"
                            sx={{backgroundColor:'',width:"70%", maxWidth:"500px"}}
                        >
                            {Object.keys(ExemptionType)
                                .filter(key => ExemptionType[key] !== ExemptionType.CANCELLED
                                    && (!exemptorReasons.includes(ExemptionType[key]) || customersWithExemptors.includes(currentCustomer.id.toString()))
                                )                                
                                .map(key => (
                                    <ToggleButton key={key} value={key} sx={{width:"100%"}} aria-label="left aligned">
                                        <Stack sx={{width:"100%"}} alignItems="center" direction="row" gap={2}>
                                            <LocalOfferIcon />
                                            {t(ExemptionType[key])}
                                        </Stack>
                                    </ToggleButton>
                                ))
                            }
                        </ToggleButtonGroup>
                    </Stack>


                    <DialogActions style={{justifyContent: 'center'}}>
                        <Button size="large" onClick={() => back(null)} variant="outlined" color="error">{t("Avbryt")}</Button>
                    </DialogActions>
                    <ExemptStatsComponent
                        regNumber={visitData.regnumberClear}
                        customerId={visitData.customerId}
                        stats={stats}
                    />
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 20 }}
                        open={(Object.keys(errors).length !== 0)}
                    >
                        {Object.keys(errors).length > 0 ?
                            <>
                                <CloseIcon onClick={close} style={{float: 'right'}}/>
                                <Typography variant="caption" display="block" gutterBottom ml={1}>
                                    {t(Object.values(errors.map(error => error.message)))}
                                </Typography>
                            </>:
                            <CircularProgress color="inherit" />
                        }
                    </Backdrop>
                </Box>
        </>

    )
}