import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridPaginationModel, GridRenderCellParams, GridSortModel, GridValueGetterParams} from '@mui/x-data-grid';
import CancelIcon from '@mui/icons-material/Cancel';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useGridApiRef } from '@mui/x-data-grid';
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import useConfirmationDialog from "../../hooks/useConfirmationDialog";
import {CustomNoRowsOverlay} from "../Shared/Tables/CustomNoRowsOverlay";
import { Link, Tooltip, Typography } from '@mui/material';
import { boxStyle, getPreRegisterRowClassName, hasVisited, isActiveDateTime } from '../Shared/Tables/listStyles';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { PreRegistration, rowToPreRegistration } from '../../types/PreRegistration';
import { updatePreRegistration } from '../../api/PreRegistrationAPI';
import UpdatePreRegistrationModal from './Modals/UpdatePreRegistrationModal';
import { canBeHandledBeforeVisit, PreRegistrationStatusType } from '../../types/enums/PreRegistrationType';
import { useCurrentCustomer } from '../../contexts/CurrentCustomerContext';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '../../contexts/CurrentUserContext';
import { nonViewerRoles } from '../../types/CurrentUserRoles';
import { CurrentUserGroups } from '../../types/CurrentUserGroups';

interface PreRegistrationTableProps {
    preRegistrationItems: PreRegistration[];
    loading: boolean;
    totalElements: number;
    paginationModel: GridPaginationModel;
    onPaginationModelChange: (newModel: GridPaginationModel) => void;
    sortModel: GridSortModel;
    onSortModelChange: (newSortModel: GridSortModel) => void;
}

const PreRegistrationTable = (({preRegistrationItems, loading, totalElements, paginationModel, onPaginationModelChange, sortModel, onSortModelChange}: PreRegistrationTableProps) => {
    dayjs.extend(utc);
    dayjs.extend(tz);
    const { t } = useTranslation();
    const confirmationDialog = useConfirmationDialog();
    const [hidden, setHidden] = useState(false);
    const toggleHidden = () => setHidden(!hidden)
    const gridApiRef = useGridApiRef();
    const [handlingVisit, setHandlingVisit] = useState(false);

    const [preRegistration, setPreRegistration] = useState<PreRegistration>(rowToPreRegistration({}));
    const [cancelling, setCancelling] = useState(false);
    const [updating, setUpdating] = useState(false);
    const today = dayjs().endOf('day');
    const {currentCustomer} = useCurrentCustomer();
    const {currentUser} = useCurrentUser();
    const navigate = useNavigate();

    const vaildForColumnGetter = (params: GridValueGetterParams) => {
        const row = params.row;
        let formattedString;
        if(dayjs(row.validFrom).isSame(today, 'day')) {
            formattedString = t("Idag");
        }
        else {
            formattedString = dayjs(row.validFrom).format('YYYY-MM-DD');
        }
        return formattedString + '   ' + dayjs(row.validFrom).format('HH:mm') + ' - ' + dayjs(row.validUntil).format('HH:mm') + '   ' + row.facilityName.name
    };

    const statusValueFormatter = (params) => {
        let formattedString = t(params.value);
        const now = dayjs().endOf('minute');
        const row = params.row;
        if(hasVisited(params.row) && canBeHandledBeforeVisit.includes(params.value)){
            return formattedString + ', ' + t("Har_besökt");
        }
        else if(canBeHandledBeforeVisit.includes(params.value) && params.value !== PreRegistrationStatusType.CANCELLED &&
            dayjs(row.validFrom).isBefore(now) && dayjs(row.validUntil).isAfter(now)) {
            return formattedString + ', ' + t("Startad");
        }
        else {
            return formattedString;
        }
    };
    const ownerValueFormatter = (params) => {
        return params.row.ownerName + ' (' + params.row.vehicleOwner + ')';
    };

    const handleVisited = (params: GridRenderCellParams) => {
        setUpdating(true);
        setPreRegistration(rowToPreRegistration(params.row));
        setHandlingVisit(true);
    };

    const isSameMonth = (params) => {
        return dayjs(params.row.validUntil).isSame(dayjs(), 'month');
    }

    const createdColumnValueGetter = (params) => {
        return params.value ? dayjs(params.value).utc(true).tz("Europe/Stockholm").format('YYYY-MM-DD HH:mm') : '';
    };

    const formatVisitTime = (visitTime: number | null) => {
        if(visitTime == null || visitTime === 0 || !dayjs(visitTime).isValid()){
            return '';
        }
        return dayjs(visitTime).format('YYYY-MM-DD HH:mm');
    };

    const columns: GridColDef[] = [
        {field: 'validFrom', headerName: t("Valid_for"), valueGetter: vaildForColumnGetter, minWidth: 50, maxWidth: 300, flex:1, disableColumnMenu: true},
        {field: 'link', headerName: "", width: 50, disableColumnMenu: true, sortable: false, hideSortIcons: true,
            renderCell: (params) => {
            return (
            <Tooltip title={t("Öppna i") + ' ' + t("Fordon_Historik")} sx={{cursor: "pointer"}}>
                <LocalShippingIcon fontSize="small" />
            </Tooltip>);
        }},
        {field: 'regnumberClear', headerName: t("Regnr_shortest"), minWidth: 50, maxWidth:100, flex: 1, disableColumnMenu: true},
        {field: 'ownerName', headerName: t("Företagsnamn"), minWidth: 50, maxWidth: 300, flex:1, disableColumnMenu: true, renderCell: (params) => {
            return <Tooltip color="inherit" title={ownerValueFormatter(params)}><Typography color='inherit' variant='body2'>{params.value}</Typography></Tooltip>
        }},
        {field: 'created', headerName: t("Inlagd"), minWidth:50, maxWidth:150, flex: 1, disableColumnMenu: true, valueGetter: createdColumnValueGetter},
        {field: 'status', headerName: t("Status"), minWidth: 50, maxWidth: 200, flex:1, disableColumnMenu: true, renderCell: (params) => {
            return (
            hasVisited(params.row) ?
            (
                (isSameMonth(params) && (hasNonViewerRole() || hasHandlePreRegistrationGroup())) ? (
                <Tooltip title={t("Besökte_vid", {visitTime: formatVisitTime(params.row.visitTime)}) + ',\n ' + t("Hantera")} sx={{cursor: "pointer"}}>
                    <Link component='button' color="inherit" onClick={() => handleVisited(params)}>{t(statusValueFormatter(params))}</Link>
                </Tooltip>
                ):
                (
                <Tooltip title={t("Besökte_vid", {visitTime: formatVisitTime(params.row.visitTime)})}>
                    <Typography color='inherit' variant='body2'>{t(statusValueFormatter(params))}</Typography>
                </Tooltip>

                )
            ):
            (
                <Tooltip title={t(statusValueFormatter(params))}><Typography color='inherit' variant='body2'>{t(statusValueFormatter(params))}</Typography></Tooltip>
            )
        )
        }},
        {field: 'edit', headerName: '', editable: false, sortable: false, disableColumnMenu: true, width: 65, renderCell: (params) => {
            return (canEdit(params) && (hasNonViewerRole() || hasHandlePreRegistrationGroup()) && <Tooltip title={t("Redigera")} sx={{cursor: "pointer"}}><EditIcon/></Tooltip>)
            }
        },
        {field: 'cancel', headerName:'', editable: false, sortable: false, disableColumnMenu: true, width: 65, renderCell: (params) => {
            return (canCancel(params) && (hasNonViewerRole() || hasHandlePreRegistrationGroup()) && <Tooltip title={t("Avbryt")} sx={{cursor: "pointer"}}><CancelIcon/></Tooltip>)
            }
        },
        {field: 'comment', headerName: t("Kommentar"), sortable: false, disableColumnMenu: true, minWidth: 50, maxWidth: 200, flex:1}
    ];

    const generateRows = (preRegistrationItem: PreRegistration): any[] => {
        const rows: any[] = [{
            id: preRegistrationItem.id, 
            validFrom: preRegistrationItem.validFrom, 
            validUntil: preRegistrationItem.validUntil,
            facilityName: preRegistrationItem.facilityName,
            created: preRegistrationItem.created,
            status: preRegistrationItem.status,
            visitTime: preRegistrationItem.visitTime,
            regnumberClear: preRegistrationItem.regnumberClear,
            ownerName: t(preRegistrationItem.ownerName),
            vehicleOwner: preRegistrationItem.vehicleOwner,
            comment: preRegistrationItem.comment
        }];

        return rows.reduce((result, currentObj) => {
            return {...result, ...currentObj};
        });
    };


    const canCancel = (params) => {
        return isActiveDateTime(params.row) && !hasVisited(params.row) && params.row.status !== PreRegistrationStatusType.CANCELLED;
    }
    const canEdit = (params) => {
        return !hasStarted(params.row) && !hasVisited(params.row) && params.row.status !== PreRegistrationStatusType.CANCELLED;
    }
    const hasStarted = (row: any) => {
        return dayjs(row.validFrom).isBefore(dayjs());
    };

    const handleConfirmCancelItem = async() => {
        const {data, errors} = await updatePreRegistration(
            preRegistration.id, 
            rowToPreRegistration({
                status: PreRegistrationStatusType.CANCELLED
            })
        );
        gridApiRef.current.updateRows([data]);
        setCancelling(false);
    };

    const handleCancelItem = (row: any) => {
        setPreRegistration(row);
        setCancelling(true);
    }

    const handleConfirmClose = () => {
        toggleHidden();
        setCancelling(false);
    };

    useEffect(() => {
        setRows(preRegistrationItems.map(generateRows));
    }, [preRegistrationItems]);

    const handleConfirmUpdateItem = async(updatedItem: PreRegistration) => {
        const {data, errors} = await updatePreRegistration(preRegistration.id, updatedItem);
        gridApiRef.current.updateRows([data]);
        handleClose();
    };

    const handleClose = () => {
        setUpdating(false);
        setHandlingVisit(false);
    };

    const [rows, setRows] = useState(() => preRegistrationItems.map(generateRows));

    const handleCellClick = (params:any) => {
        if(params.field === 'cancel' && canCancel(params)) {
            handleCancelItem(params.row);
        } else if(params.field === 'edit' && canEdit(params)) {
            setUpdating(true);
            setPreRegistration(rowToPreRegistration(params.row));
        }
        else if(params.field === 'link') {
            navigate(`/vehiclehistory/${currentCustomer.id}?regnumber=${params.row.regnumberClear}`)
        }
    }
    const hasNonViewerRole = () => {
        return currentUser && currentUser.isAuthorized && nonViewerRoles.some(r => currentUser.roles.some(cur => cur.id === r.id));
    }

    const hasHandlePreRegistrationGroup = () => {
        return currentUser && currentUser.isAuthorized && currentUser.groups.some(ug => ug.name.toUpperCase().includes(CurrentUserGroups.HANDLE_PRE_REGISTRATION) && currentCustomer.customerGroups.some(cg => cg.id === ug.id));
    }
    
    return (
        <Box sx={boxStyle}>
            <Paper>
                <DataGrid
                    sx={{height: '55vh', maxHeight: '65vh', width: '100%', '--DataGrid-overlayHeight': '300px'}}
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    getRowClassName={getPreRegisterRowClassName}
                    getRowId={(row: any) => row.id}
                    paginationMode='server'
                    rowCount={totalElements?? 0}
                    paginationModel={paginationModel}
                    onPaginationModelChange={onPaginationModelChange}
                    sortingMode='server'
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    sortingOrder={['desc', 'asc']}
                    pageSizeOptions={[10, 25, 50, 100]}
                    hideFooterSelectedRowCount={true}
                    apiRef = {gridApiRef}
                    onCellClick={handleCellClick}
                    rowSelection={false}
                    hideFooter={false}
                    slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                        pagination: {
                            showFirstButton: true,
                            showLastButton: true,
                        },
                    }}
                />
            </Paper>
            <Box sx={{maxWidth: {xs: '100%', sm: '95%'}}}>
                <Grid container spacing={2}>
                    <Grid item xs="auto" sx={{ marginLeft: { xs: 0, md: "auto"}}}>
                        <UpdatePreRegistrationModal 
                            open={updating} 
                            handleClose={handleClose} 
                            handleConfirmChanges={handleConfirmUpdateItem} 
                            data={preRegistration} 
                            mode={handlingVisit? 'handle' : 'edit'}/>
                    </Grid>
                </Grid>
            </Box>
            <ConfirmationDialog
                open={cancelling}
                handleClose={() => confirmationDialog.handleClose(handleConfirmClose)}
                handleAgree={() => confirmationDialog.handleAgree(handleConfirmCancelItem)}
                title={t("Avbryt_Föranmälan")}
                subtitle={preRegistration.regnumberClear + ' ' + t("kommer_att_avbrytas")}
                content={{}}
                errors={null}
                list={[]}
                active={false}
                addtionalConfirmationText={t("Jag_avbryter") + ' ' + preRegistration.regnumberClear}
            />
        </Box>
);
});
export default PreRegistrationTable;