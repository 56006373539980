import { UserGroup } from "./CurrentUserGroups";
import {Service} from "./Service";

export const customersWithUserNameLoginType = ['1', '2', '3'];

export const customersWithExemptors = ['7'];

export interface Customer {
    id: string,
    fullName: string,
    name: string,
    orgnumber: string,
    comment: string,
    active: boolean,
    clientRef: string,
    services: Service[],
    facilityNames: [],
    productNames: string[],
    customerGroups: UserGroup[]
}

