import React from "react";
import {styled} from "@mui/material/styles";
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {alpha, AppBar, FormControlLabel, FormGroup, Switch, Typography, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import useHideOnRoute from "../../hooks/useHideOnRoute";
import {Link as RouterLink} from "react-router-dom";
import {ArrowBack} from "@mui/icons-material";
import { grey,} from "@mui/material/colors";
import SearchBar from "../Shared/SearchBar";


interface CustomAppBarProps extends MuiAppBarProps {
    open?: boolean;
    hidden?: boolean;
    handleDrawerOpen: () => void;
    searchQuery: string;
    handleSearchQuery: (query: string) => void;
    filterPreRegistered: boolean;
    handleFilterPreRegistered: (value: boolean) => void;
}

const CustomMobileAppBar: React.FC<CustomAppBarProps> = ({
                                                             hidden,
                                                             handleDrawerOpen,
                                                             searchQuery,
                                                             handleSearchQuery,
                                                             filterPreRegistered,
                                                             handleFilterPreRegistered
                                                         }) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const hideOnSettingsRoute = useHideOnRoute("/settings");
    const hideOnHelpRoute = useHideOnRoute("/help");
    const hideOnProblemReportRoute = useHideOnRoute("/report");
    const hideOnFacilitySelectRoute = useHideOnRoute("/facilityselection")

    const handleInputChange = (event) => {
        handleSearchQuery(event.target.value.replace(/\s/g, '').toUpperCase());
    };

    const handleSelectedChange = () => {
        handleFilterPreRegistered(!filterPreRegistered);
    }

    const StyledSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: grey[50],
          '&:hover': {
            backgroundColor: alpha(grey[50], theme.palette.action.hoverOpacity),
          },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: grey[50],
        },
      }));

    return (
        <AppBar position="fixed" hidden={hidden}>
            <Toolbar>
                <Grid container
                      direction="row"
                      alignItems="center"
                      spacing={1}>
                    {!hideOnSettingsRoute 
                        && !hideOnHelpRoute 
                        && !hideOnProblemReportRoute 
                        && !hideOnFacilitySelectRoute
                        &&
                        <>
                            <Grid item xs={6}>
                                <SearchBar
                                    label={t("Sök_mobil")}
                                    size= "small"
                                    hideLabel={true}
                                    onChange={handleInputChange}
                                    searchQuery={searchQuery}
                                    minWidth={125}
                                />
                            </Grid>
                            <Grid item xs={5}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <StyledSwitch
                                        sx={{ml: 2}}
                                        color="default"
                                        value="check"
                                        size={"small"}
                                        checked={filterPreRegistered}
                                        onChange={handleSelectedChange}
                                    />
                                }
                                    label={t("Föranmälda")}
                                />
                            </FormGroup>
                            </Grid>
                        </>
                    }
                    {hideOnFacilitySelectRoute &&
                        <>
                            <Grid item xs={2}>
                                <RouterLink to={"/realtimeview"}>
                                    <IconButton>
                                        <ArrowBack/>
                                    </IconButton>
                                </RouterLink>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    sx={{color: "black", textDecoration: "none"}}>
                                    {t("Välj_Anläggning")}
                                </Typography>
                            </Grid>
                        </>
                    }
                    {hideOnSettingsRoute &&
                        <>
                            <Grid item xs={2}>
                                <RouterLink to={"/realtimeview"}>
                                    <IconButton>
                                        <ArrowBack/>
                                    </IconButton>
                                </RouterLink>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    sx={{color: "black", textDecoration: "none"}}>
                                    {t("Inställningar")}
                                </Typography>
                            </Grid>
                        </>
                    }
                    {hideOnHelpRoute &&
                        <>
                            <Grid item xs={2}>
                                <RouterLink to={"/realtimeview"}>
                                    <IconButton>
                                        <ArrowBack/>
                                    </IconButton>
                                </RouterLink>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    sx={{color: "black", textDecoration: "none"}}>
                                    {t("Hjälp")}
                                </Typography>
                            </Grid>
                        </>
                    }
                    {hideOnProblemReportRoute &&
                        <>
                            <Grid item xs={2}>
                                <RouterLink to={"/realtimeview"}>
                                    <IconButton>
                                        <ArrowBack/>
                                    </IconButton>
                                </RouterLink>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    sx={{color: "black", textDecoration: "none"}}>
                                    {t("Rapportera_ett_problem")}
                                </Typography>
                            </Grid>
                        </>
                    }
                    <Grid item xs={1}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: "0",
                                marginLeft: "auto",
                                paddingLeft: "0",
                                display: "flex",
                                alignItems: "center",
                                gridGap: theme.spacing(1)
                            }}>
                            <MenuIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default CustomMobileAppBar;
