export interface UserGroup {
    id: number,
    name: string
}

export enum CurrentUserGroups {
    MARKED_SERVICES = "MARKED_SERVICES",
    EXEMPTING = "UNDANTAR",
    HANDLE_PRE_REGISTRATION = "FÖRANMÄLAN",
    CSV_EXPORT = "CSV EXPORT",
    LIST = "LISTOR"
}
